import Link from "next/link";

export default function Header() {
  return (
    <h2 className="text-2xl md:text-4xl font-bold tracking-tight md:tracking-tighter leading-tight mt-10 mb-8">
      <Link href="/">
        <img
          className="block dark:hidden"
          src="/images/logo-w.svg"
          width="160"
          height="24"
          alt="Logo Salazarpardo Design & Development"
        />
        <img
          className="hidden dark:block"
          src="/images/logo.svg"
          width="160"
          height="24"
          alt="Logo Salazarpardo Design & Development"
        />
      </Link>
    </h2>
  );
}
