import Container from "./container";
import { EXAMPLE_PATH } from "../lib/constants";
import Link from "next/link";

export default function Footer() {
  return (
    <footer className="bg-accent-1 dark:bg-slate-500 border-t border-accent-2 dark:border-slate-300">
      <Container>
        <div className="py-28 flex flex-col">
          <h3 className="text-center mb-16">
            <Link href="/" name="Home">
              <img
                className="block dark:hidden"
                src="/images/logo-w.svg"
                width="160"
                height="24"
                alt="Logo Salazarpardo Design & Development"
              />
              <img
                className="hidden dark:block"
                src="/images/logo.svg"
                width="160"
                height="24"
                alt="Logo Salazarpardo Design & Development"
              />
            </Link>
          </h3>
          <div className="flex flex-col">
            <small> © 2010 - {new Date().getFullYear()}</small>
          </div>
        </div>
      </Container>
    </footer>
  );
}
