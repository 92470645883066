import Link from "next/link";
import { useEffect } from "react";
import { AnimatePresence, motion, useCycle } from "framer-motion";
import Header from "../components/header";
import Footer from "../components/footer";
import Meta from "../components/meta";

const links = [
  {
    name: "Home",
    to: "/",
    id: 1,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
        />
      </svg>
    ),
  },
  {
    name: "Case studies",
    to: "/posts",
    id: 2,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
        />
      </svg>
    ),

    children: [
      { name: "Goodkind", to: "/posts/goodkind", id: 1 },
      { name: "Android Auto", to: "/posts/auto", id: 2 },
      { name: "WP Web Stories", to: "/posts/stories", id: 3 },
      { name: "Elenas", to: "/posts/elenas", id: 4 },
      { name: "Chango", to: "/posts/chango", id: 5 },
      { name: "Rocket", to: "/posts/rocket", id: 6 },
    ],
  },
  {
    name: "Product Sprints",
    to: "/sprints",
    id: 3,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z"
        />
      </svg>
    ),
  },
  {
    name: "About",
    to: "/about",
    id: 4,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
        />
      </svg>
    ),
  },
  {
    name: "Contact",
    to: "/contact",
    id: 5,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
        />
      </svg>
    ),
  },
];

const sideVariants = {
  closed: {
    transition: {
      staggerChildren: 0.2,
      staggerDirection: -1,
    },
  },
  open: {
    transition: {
      staggerChildren: 0.2,
      staggerDirection: 1,
    },
  },
};

const itemVariants = {
  closed: {
    opacity: 0,
  },
  open: { opacity: 1 },
};

export default function Layout({ preview, children }) {
  const [open, cycleOpen] = useCycle(false, true);

  useEffect(() => {
    document.body.classList.toggle("no-scroll", open);
  }, [open]);

  return (
    <>
      <Meta />
      <AnimatePresence>
        {open ? (
          <motion.aside
            id="drawer-navigation"
            className="rounded-tl-3xl fixed z-40 right-0 h-screen px-4 overflow-y-auto bg-white text-slate-300 dark:bg-slate-300 dark:text-white w-80"
            tabIndex="-1"
            aria-labelledby="drawer-navigation-label"
            initial={{ width: 0 }}
            animate={{ width: 300 }}
            exit={{
              width: 0,
              transition: { delay: 0.3, duration: 0.3 },
            }}
          >
            <motion.div
              variants={itemVariants}
              initial={{ opacity: 0, marginTop: 40 }}
              whileInView={{ opacity: 1, marginTop: 0 }}
              exit="closed"
              className="px-2 -mb-4"
              onClick={cycleOpen}
            >
              <Header id="drawer-navigation-label" />
            </motion.div>

            <motion.button
              type="button"
              data-drawer-dismiss="drawer-navigation"
              aria-controls="drawer-navigation"
              className="absolute text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm focus:ring-1 focus:ring-blue-300 p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={cycleOpen}
              variants={itemVariants}
              exit="closed"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close menu</span>
            </motion.button>

            <motion.div
              className="py-2 overflow-y-auto"
              initial="closed"
              animate="open"
              exit="closed"
              variants={sideVariants}
            >
              <motion.ul
                className="space-y-2"
                variants={itemVariants}
                exit="closed"
              >
                {links.map(({ name, icon, to, id, children }) => (
                  <motion.li key={id} variants={itemVariants} exit="closed">
                    {children && children.length ? (
                      <>
                        <Link
                          className="flex items-center w-full p-2 text-base font-normal text-gray-900 transition duration-75 rounded-lg group hover:bg-blue-300 hover:text-white dark:text-white"
                          href={to}
                          onClick={cycleOpen}
                        >
                          {icon}
                          <span className="ml-3">{name}</span>
                        </Link>
                        <ul id="dropdown-example" className="py-2 space-y-2">
                          {children.map(({ name, to, id }) => (
                            <li key={id}>
                              <Link
                                href={to}
                                className="flex items-center w-full p-2 text-base font-normal text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-blue-300 hover:text-white dark:text-white"
                                onClick={cycleOpen}
                              >
                                {name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </>
                    ) : (
                      <Link
                        className="flex items-center p-2 text-base font-normal text-gray-900 transition duration-75 rounded-lg hover:bg-blue-300 hover:text-white dark:text-white "
                        href={to}
                        onClick={cycleOpen}
                      >
                        {icon} <span className="ml-3">{name}</span>
                      </Link>
                    )}
                  </motion.li>
                ))}
              </motion.ul>
            </motion.div>
          </motion.aside>
        ) : (
          <aside id="drawer-navigation"></aside>
        )}
      </AnimatePresence>
      <div
        onClick={open ? cycleOpen : null}
        className={
          open
            ? "relative opacity-50 overflow-hidden cursor-pointer"
            : "relative"
        }
      >
        <div className="fixed z-20 left-0 right-0 top-0">
          <motion.div
            className="container bg-slate-50/[.9] dark:bg-slate-400/[.9] relative flex mx-auto px-5"
            initial={{ opacity: 0, marginTop: 20 }}
            whileInView={{ opacity: 1, marginTop: 0 }}
          >
            <Header />
            <button
              className="right-0 top-6 absolute text-slate-300 dark:text-white hover:text-blue-200 focus:ring-1 focus:ring-blue-300 font-medium rounded-lg text-sm p-4 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 transition duration-150 ease-out hover:ease-in"
              type="button"
              data-drawer-target="drawer-navigation"
              data-drawer-show="drawer-navigation"
              aria-controls="drawer-navigation"
              onClick={cycleOpen}
              title={open ? "Close" : "Open"}
            >
              {open ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              )}
            </button>
          </motion.div>
        </div>

        {/* <Alert preview={preview} /> */}
        <main className="pt-24">{children}</main>
        <Footer />
      </div>
    </>
  );
}
